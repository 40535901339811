.tab-item {
  padding       : 0.25rem 0.5rem;
  border-radius : var(--normal-radius);
  font-size     : var(--text-small-size);
  color         : var(--text-light-color);
  transition    : background var(--transition-time);
  white-space   : nowrap;

  @include active-status {
    cursor     : pointer;
    color      : var(--blue-800);
    background : var(--blue-100);
  }
}

.tab-container,
.tabs-container {
  display   : flex;
  flex-wrap : nowrap;
  gap       : 0.5rem;
  overflow  : auto;

  &.border {
    padding-left  : 1rem;
    border-bottom : 1px solid var(--border-color);

    .tab-item {
      @include active-status {
        border-radius : var(--normal-radius) var(--normal-radius) 0 0;
      }
    }
  }
}
