.publication-images {
  display      : grid;
  gap          : 1rem;
  aspect-ratio : 16 / 9;
  padding-top  : 1rem;

  &:not(.edit-mode) .image:hover {
    opacity   : 0.9;
    transform : scale(1.025);
  }

  .image {
    display       : block;
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : var(--normal-radius);
    cursor        : pointer;
    transition    : opacity var(--transition-time), transform var(--transition-time);
  }

  &:has(.image-container:nth-child(2)) {
    grid-template-columns : 1fr 1fr;
  }

  &:has(.image-container:nth-child(3)):not(:has(.image-container:nth-child(4))) {
    grid-template-rows : 1fr 1fr;

    .image-container:first-child {
      grid-row-start : span 2;
    }
  }

  .image-container {
    position : relative;
  }
}
