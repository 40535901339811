.header-user-avatar {
  position : relative;

  .user {
    display          : flex;
    align-items      : center;
    gap              : 0.5rem;
    background-color : var(--grey-alt-bg);
    border-radius    : 1rem;
    cursor           : pointer;
    user-select      : none;
    transition       : background-color var(--transition-time);

    @include from(md) {
      padding-right : 1rem;
    }

    &:hover {
      background-color : var(--border-color);
    }

    .user-avatar, .employer-avatar {
      --size : 2rem;
    }
  }

  .float-container {
    top : 2.25rem;
  }
}
