.profile-card {
  padding  : 0;
  overflow : hidden;

  @include from(md) {
    position : sticky;
    top      : var(--top-content);
  }

  .header {
    padding-top : 2rem;
    height      : 5rem;
    background  : linear-gradient(89.24deg, #2B69AB 0%, #3FA1FF 99.87%);
  }

  .user-avatar, .employer-avatar {
    margin-inline : auto;
    --size        : 6rem;
  }

  .content {
    display        : grid;
    place-items    : center;
    gap            : 1rem;
    padding        : var(--container-padding);
    padding-bottom : 1.5rem;
    padding-top    : 4rem;
    text-align     : center;
  }

  .title {
    margin-bottom : 0;
  }

  .social-network-container {
    display : flex;
    gap     : 0.75rem;
  }

  .social-network-button {
    color : currentColor;
  }

  .info-list {
    display : grid;
    gap     : 0.5rem;
  }
}
