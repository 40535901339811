.home-page {
  margin-top    : var(--top-content);
  padding-bottom: 6rem;

  @include from(md) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include from(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  &,
  .main,
  .feed {
    display      : grid;
    gap          : 1.5rem;
    align-content: start;
  }

  .feed {
    @include from(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    & .content {
      @include from(lg) {
        grid-column: 2 span;
      }
    }
  }

  .aside {
    display       : flex;
    flex-direction: column;
    gap           : 2rem;

    @include from-to(md, lg) {
      grid-column-start: span 2;
    }
  }

  .main {
    @include from(md) {
      grid-column-start: span 3;
    }
  }
}
