.nav-item {
  display         : flex;
  align-items     : center;
  gap             : 0.35rem;
  padding         : 0.75rem 0.5rem;
  border-radius   : var(--normal-radius);
  color           : var(--text-color);
  text-decoration : none;

  .icon {
    flex : none;
  }

  .text {
    white-space : nowrap;
  }

  @include active-status {
    color      : var(--blue-accent);
    background : var(--blue-light-accent);
  }

  &.logout {
    @include active-status {
      color      : var(--red-accent);
      background : var(--red-light-accent);
    }
  }
}
