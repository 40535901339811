// Texto y decoraciones
.decoration-none {
  text-decoration : none;

  &:hover {
    text-decoration : none;
  }
}

.whitespace-nowrap {
  white-space : nowrap;
}

.overflow-ellipsis {
  overflow      : hidden;
  text-overflow : ellipsis;
  white-space  : nowrap;
}

span.link {
  color: var(--blue-400);
  cursor: pointer;
}
