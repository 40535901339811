.drop-container {
  .container {
    padding-block    : 1.5rem 2rem;
    border           : 2px dashed var(--border-color);
    order            : 1;
    border-radius    : var(--normal-radius);
    color            : var(--text-light-color);
    background-color : var(--input-bg);
  }

  .file {
    width  : 4rem;
    height : 4rem;
  }

  &.tiny {
    .container {
      display         : flex;
      flex-direction  : row;
      justify-content : center;
      padding         : 0.5rem 1rem;
      border-width    : 1.5px;
    }

    .icon {
      @include size(1.25rem);
      margin-right : 0.25rem;
    }
  }

  &.is-drag {
    .container {
      background-color : var(--blue-light-accent);
      border-color     : var(--blue-300);
      color            : var(--blue-accent);
    }
  }

  &.is-uploaded {
    .container {
      background-color : var(--light-green-accent);
      border-color     : var(--green-accent);
      color            : var(--green-accent)
    }
  }
}
