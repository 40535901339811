@mixin size($width, $height : $width) {
  width  : $width;
  height : $height;
}

@mixin active-status {
  &:hover,
  &.is-active,
  &.active {
    @content;
  }
}
