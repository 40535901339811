// Cursor
.cursor-pointer {
  cursor : pointer;
}

.select-none {
  user-select : none;
}

.outline-none {
  outline : none;
}

.events-none {
  pointer-events : none;
}
