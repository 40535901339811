.wrapper {
  --margin      : 1rem;
  width         : min(100% - var(--margin), 1200px);
  margin-inline : auto;

  @include from(md) {
    --margin : 3rem;
  }
}

:where(.n-container) {
  margin-bottom    : 0;
  padding          : var(--container-padding);
  border-radius    : var(--big-radius);
  background-color : var(--container-bg);
  box-shadow       : var(--card-shadow);

  &.no-padding {
    padding : 0;
  }
}

.float-container {
  position         : absolute;
  padding          : 1rem;
  min-width        : min-content;
  border-radius    : var(--normal-radius);
  background-color : var(--container-bg);
  box-shadow       : var(--tooltip-shadow);
  z-index          : var(--z-tooltip);

  &.right-side {
    @include to(md) {
      right : 0;
    }
  }
}
