.button {
  display         : inline-block;
  padding         : 0.5rem 1rem;
  border-radius   : var(--normal-radius);
  border          : 1px solid var(--blue-accent);
  background      : var(--blue-accent);
  color           : #fff;
  font-family     : var(--button-font);
  font-size       : var(--text-base-size);
  line-height     : var(--body-line-height);
  letter-spacing  : 0.3px;
  text-align      : center;
  text-decoration : none;
  cursor          : pointer;
  outline         : none;
  user-select     : none;
  transition      : background-color var(--transition-time);

  &:active {
    transform : scale(0.99);
  }

  &:hover {
    text-decoration : none;
    background      : var(--blue-alt-accent);
    border-color    : var(--blue-alt-accent);
  }

  &.ghost {
    color            : var(--blue-accent);
    background-color : transparent;
    border-color     : var(--text-lighter-color);

    &:hover {
      color        : #FFF;
      background   : var(--blue-accent);
      border-color : var(--blue-accent);
    }
  }

  &.light {
    color            : var(--blue-600);
    background-color : var(--blue-100);
    border-color     : var(--blue-100);

    &:hover {
      background-color : var(--blue-200);
      border-color     : var(--blue-100);
    }
  }

  &.cancel,
  &.delete {
    background-color : transparent;
    border-color     : var(--text-lighter-color);

    &:hover {
      color : #fff;
    }
  }

  &.cancel {
    color : var(--text-color);

    &:hover {
      background   : var(--text-lighter-color);
      border-color : var(--text-lighter-color);
    }
  }

  &.delete {
    color : var(--red-accent);

    &:hover {
      background   : var(--red-accent);
      border-color : var(--red-accent);
    }
  }

  &.full {
    margin-right : 0;
    margin-left  : 0;
    width        : 100%;
  }

  &:disabled, &:disabled:hover {
    background   : var(--border-color);
    color        : var(--text-light-color);
    border-color : var(--border-color);
    cursor       : not-allowed;
  }

  // Sizes
  &.small {
    padding   : rem(3px) 0.5rem;
    font-size : var(--text-small-size);
  }

  &.micro {
    padding       : 0 0.25rem;
    border-radius : var(--small-text-radius);
    font-size     : var(--text-smaller-size);
  }
}
