:where(.user-avatar) {
  --size        : 1.5rem;
  @include size(var(--size));
  border-radius : 50%;
  overflow      : hidden;
  flex          : none;

  @include from(md) {
    --size : 2rem;
  }

  img {
    width      : 100%;
    height     : 100%;
    object-fit : cover;
  }

  &.small {
    --size : 1.25rem;

    @include from(md) {
      --size : 1.5rem;
    }
  }

  &.big {
    --size : 2rem;

    @include from(md) {
      --size : 2.5rem;
    }
  }

  &.bigger {
    --size : 3rem;

    @include from(md) {
      --size : 4rem
    }
  }

  .user-avatar-list & {
    box-shadow : 0 0 0 2px white;

    &:not(:first-of-type) {
      margin-left : -0.25rem;
    }
  }
}
