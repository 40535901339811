.publication {
  --photo-gap      : 1rem;
  --answer-padding : 3rem;
  order            : 3;

  @include from(md) {
    --answer-padding : 4rem;
  }

  .header {
    display         : flex;
    justify-content : space-between;
    align-items     : start;
    margin-bottom   : 0.5rem;
  }

  .user {
    display : flex;
    gap     : var(--photo-gap);

    .name {
      margin-bottom : 0;
    }

    .time {
      display    : block;
      margin-top : -0.2rem;
      color      : var(--text-light-color);
      font-size  : var(--text-smaller-size);
    }
  }

  .right {
    display     : flex;
    align-items : center;
    gap         : 1rem;
    z-index     : 1;

    .icon-text {
      color : var(--text-light-color);
    }
  }

  :where(.content) {
    margin-bottom : 1rem;
    font-size     : var(--text-small-size);

    p {
      margin-bottom : 0;
    }
  }

  .footer {
    display         : flex;
    justify-content : space-between;
    align-items     : center;

    &:not(:last-child) {
      margin-bottom : 1.5rem;
    }
  }

  .buttons {
    display : flex;
    gap     : 0.5rem;
  }

  .comment-section {
    display : grid;
    gap     : 1rem;

    @include from(md) {
      gap : 1.5rem;
    }
  }

  &.is-fixed {
    order            : 2;
    background-color : var(--yellow-50);
  }
}
