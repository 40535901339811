.background-wrapper {
  width              : 100vw;
  height             : 100vh;
  background-image   : url(../../../static/utils/background-wrapper.svg);
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : cover;
  display            : flex;
  align-items        : center;
  justify-content    : center;
}
