.publication-form {
  order : 1;

  .input-container {
    display : flex;

    /*&:has(.input:not(:focus)) ~ .footer {
      display : none;
    }

    &:has(.input:not(:empty)) ~ .footer {
      display : flex;
    }*/
  }

  .input {
    font-size : var(--text-small-size);
    padding   : 0.5rem 1rem;
    outline   : none;
    width     : 100%;
  }

  .footer {
    display         : flex;
    align-items     : center;
    justify-content : end;
    gap             : 0.75rem;

    .separator {
      border-left : 1px solid var(--border-color);
      height      : 1.25rem;
    }
  }

  .publication-images,
  .publication-files {
    margin-bottom : 1rem;
  }
}
