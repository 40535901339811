@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

:root {
  // Fonts
  --title-font        : 'Varela Round', sans-serif;
  --body-font         : 'Varela Round', sans-serif;
  --button-font       : 'Roboto', sans-serif;

  // Line height
  --title-line-height : 1.3;
  --body-line-height  : 1.6;

  // Sizes
  @mixin base-font-size($name, $mobile-size, $desktop-size) {
    #{$name} : #{rem($mobile-size)};

    @include from(lg) {
      #{$name} : #{rem($desktop-size)};
    }
  }

  @include base-font-size(--title-bigger-size, 26px, 32px);
  @include base-font-size(--title-big-size, 20px, 26px);
  @include base-font-size(--title-base-size, 16px, 20px);
  @include base-font-size(--title-small-size, 14px, 16px);
  @include base-font-size(--text-base-size, 16px, 16px);
  @include base-font-size(--text-small-size, 14px, 14px);
  @include base-font-size(--text-smaller-size, 12px, 12px);
  @include base-font-size(--text-smallest-size, 10px, 10px);
}

// Estilos base para las fuentes
// Si el segundo parámetro es true, entonces se aplican estilos de título, en caso contrario estilos de contenido
@mixin base-font-styles($size, $is-title: false) {
  line-height : if($is-title, var(--title-line-height), var(--body-line-height));
  font-family : if($is-title, var(--title-font), var(--body-font));
  font-size   : var(--#{$size}-size);

  @if ($is-title) {
    color          : var(--title-color);
    letter-spacing : 0.015em;
    font-weight    : normal;
  }
}

@mixin title-bigger {
  @include base-font-styles(title-bigger, true);
}

@mixin title-big {
  @include base-font-styles(title-big, true);
}

@mixin title-base {
  @include base-font-styles(title-base, true);
}

@mixin title-small {
  @include base-font-styles(title-small, true);
}

@mixin text-base {
  @include base-font-styles(text-base);
}

@mixin text-small {
  @include base-font-styles(text-small);
}

@mixin text-smaller {
  @include base-font-styles(text-smaller);
}

@mixin text-smallest {
  @include base-font-styles(text-smallest);
}

.title-bigger, h1 {
  @include title-bigger;
}

.title-big, h2 {
  @include title-big;
}

.title-base, h3 {
  @include title-base;
}

.title-small, h4 {
  @include title-small;
}

.base-text, body {
  @include text-base;
}

.text-small {
  @include text-small;
}

.text-smaller {
  @include text-smaller;
}

.text-smallest {
  @include text-smallest;
}

.title-font {
  font-family : var(--title-font);
}

.body-font {
  font-family : var(--body-font);
}

.button-font {
  font-family : var(--button-font);
}
