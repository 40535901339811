.alert {
  position       : fixed;
  bottom         : 1rem;
  left           : 50%;
  transform      : translateX(-50%);
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding        : 1rem;
  min-width      : 20vw;
  max-width      : 70vw;
  border-radius  : var(--normal-radius);
  z-index        : var(--z-modal);

  .message {
    display     : flex;
    margin-right: 1rem;

    .icon {
      margin-right: 1rem;
    }
  }

  .icon {
    width : 1.5rem;
    height: 1.5rem;
  }

  .close-button {
    cursor: pointer;
  }

  &.error {
    color           : var(--red-alt-accent);
    background-color: var(--red-light-accent);
  }

  &.success {
    color           : var(--green-alt-accent);
    background-color: var(--green-light-accent);
  }

  &.info {
    color           : var(--blue-alt-accent);
    background-color: var(--blue-light-accent);
  }

  &.warning {
    color           : var(--yellow-alt-accent);
    background-color: var(--yellow-light-accent);
  }
}
