.dropdown-document {
  position : relative;
  z-index  : 10;

  .calendar {
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    gap             : 0.5rem;
  }

  .header-dropdown {
    display : flex;
    gap     : 0.5rem;
  }

  .float-container {
    @include from(443px) {
      right : 0;
    }

    @include from(lg) {
      right     : 50%;
      transform : translateX(50%);
    }
  }
}
