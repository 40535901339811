.documents-section {
  .header {
    border-bottom: 1px solid var(--border-color);
  }

  .header,
  .header-content {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    gap            : 0.75rem;
    flex-wrap      : wrap;
  }

  .header,
  .content {
    padding: var(--container-padding);
  }

  .header-content {
    margin-bottom: 1rem;
  }

  & .header-options {
    display    : flex;
    align-items: center;
    gap        : 0.75rem;
  }

  .pdf-viewer {
    box-shadow: var(--document-shadow);
  }
}
