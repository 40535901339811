:root {
  // Transition time
  --transition-time      : 0.25s;
  --min-transition-time  : 0.15s;
  --long-transition-time : 0.5s;
}

$transitions : background, transform, height, color, padding, width, opacity;

@each $transition in $transitions {
  .#{$transition}-transition {
    transition : $transition var(--transition-time);
  }
}

.hover-change {
  transition : background-color var(--transition-time);

  @include active-status {
    background-color : var(--grey-alt-bg);

    &.blue {
      background-color : var(--blue-light-accent);
    }

    &.red {
      background-color : var(--red-light-accent);
    }
  }
}
