.config-page {
  .content-config {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    margin-inline  : auto;
    max-width      : rem(600px);
  }

  .form-config {
    width      : 100%;
    text-align : center;
  }

  .title,
  .tabs-container {
    margin-bottom : 0.75rem;
  }

  .copy-form {
    font-size     : var(--text-smaller-size);
    margin-bottom : 0.5rem;

    .accent {
      color : var(--blue-accent);
    }
  }

  .networks-panel {
    padding-top : 0.75rem;
  }

  .drop-container {
    @include from(md) {
      grid-column-start : span 2;
    }
  }

  .form-grid {
    margin-bottom : 2rem;
  }
}
