.notifications {
  position    : relative;
  display     : flex;
  align-items : center;
}

.notification-button {
  all        : unset;
  position   : relative;
  display    : flex;
  cursor     : pointer;
  transition : transform var(--min-transition-time);

  &:hover {
    transform : scale(1.1);
  }

  .dot {
    position         : absolute;
    top              : 0.25rem;
    right            : 0.25rem;
    @include size(0.5rem);
    background-color : var(--blue-accent);
    border-radius    : 50%;
  }
}

.notifications-panel {
  display        : flex;
  flex-direction : column;
  width          : 100%;

  @include to(md) {
    position      : fixed;
    top           : var(--header-height);
    left          : 0;
    height        : calc(100vh - var(--header-height));
    padding       : 1rem;
    border-radius : initial;
  }

  @include from(md) {
    position   : absolute;
    top        : 2rem;
    right      : -1rem;
    width      : rem(450px);
    max-height : rem(424px);
    box-shadow : var(--modal-shadow);
  }

  .header {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    margin-bottom   : 1rem;

    @include from(md) {
      margin-bottom : 1.5rem;
    }
  }

  .see-all-button {
    all    : unset;
    color  : var(--blue-accent);
    cursor : pointer;
  }
}

.notification-list {
  display   : grid;
  gap       : 0.5rem;
  flex-grow : 1;
  overflow  : auto;
}

.notification-item {
  display       : flex;
  gap           : 0.5rem;
  padding       : 0.25rem;
  border-radius : var(--normal-radius);
  font-size     : var(--text-small-size);
  cursor        : pointer;
  transition    : background-color var(--transition-time);

  &:hover {
    background-color : var(--grey-alt-bg);
  }

  .title {
    color : var(--title-color);
  }

  .time {
    color     : var(--text-lighter-color);
    font-size : var(--text-smaller-size);
  }

  .user-avatar {
    --size : 1.5rem;
  }

  &.unread {
    background-color : var(--blue-light-accent);

    &:hover {
      background-color : var(--blue-100);
    }
  }

  &.mention .icon {
    color : var(--blue-accent);
  }
}
