[type='text'],
[type='email'],
[type='password'],
[type='number'],
[type='date'],
[type='time'],
[type='datetime-local'],
[type='search'],
[type='tel'],
[type='url'],
select,
textarea,
.input-field {
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--normal-radius);
  background-color: var(--input-bg);
  line-height: var(--body-line-height);
  font-family: var(--body-font);
  font-size: var(--text-small-size);
  color: var(--text-color);

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--blue-accent);
  }

  &::placeholder {
    color: var(--text-lighter-color);
  }

  &:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
  }
}

[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: var(--text-light-color);
}

select {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: rem(116px);
}

[type='password'],
[placeholder='••••••••••']:placeholder-shown {
  letter-spacing: 0.25rem;
}

@function input-icon($icon) {
  @return var(--input-bg) url($icon) center right 1rem / 1rem no-repeat;
}

[type='search']:placeholder-shown {
  background: input-icon('../../../static/icons/svg/search.svg');
}

input.date-picker {
  background: input-icon('../../../static/icons/svg/calendar.svg');
}

select {
  appearance: none;
  background: input-icon('../../../static/icons/svg/down.svg');

  &.has-placeholder:not(:focus) {
    color: var(--text-lighter-color);
  }

  &.small {
    height: 2rem;
    font-size: var(--text-small-size);
    padding: 0.2rem 2rem 0.2rem 1rem;
  }
}

.radio-button {
  display: flex;
  align-items: center;
  font-size: var(--text-small-size);

  input {
    margin: 0;
    margin-right: rem(6px);
  }
}

@mixin required-label {
  &::before {
    content: '*';
    margin-right: rem(6px);
    color: var(--blue-accent);
  }
}

.form-item {
  display: flex;
  flex-direction: column;

  input,
  select {
    &:required+label {
      @include required-label;
    }
  }

  label:not(.radio-button) {
    display: block;
    text-align: left;
    font-size: var(--text-small-size);
    margin-bottom: rem(6px);
    order: -1;
  }

  &.small input {
    height: 2rem;
  }
}

label.required {
  @include required-label;
}

.checkbox-item {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    appearance: none;
    margin: 0;
    margin-right: rem(6px);
    outline: none;

    &::before {
      content: '';
      @include size(rem(14px));
      display: block;
      box-shadow: 0 0 0 2px var(--text-color) inset;
      border-radius: var(--small-radius);
    }

    &:checked::before {
      content: '✔';
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      background-color: var(--blue-accent);
      color: #fff;
      font-size: 0.65rem;
      line-height: 1rem;
    }
  }
}