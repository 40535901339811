.aside-section {
  .title {
    display       : flex;
    align-items   : center;
    gap           : 0.25rem;
    margin-bottom : 0.75rem;
  }

  .list {
    display : grid;
    gap     : 0.5rem;
  }
}
