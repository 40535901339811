.user-item {
  display   : flex;
  gap       : 0.5rem;
  font-size : var(--text-small-size);

  .sub-content {
    font-size : var(--text-smaller-size);
    color     : var(--text-light-color);
  }
}
