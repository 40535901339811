.delete-button {
  --size           : 1.5rem;
  all              : unset;
  position         : absolute;
  top              : calc(var(--size) / 3 * -1);
  right            : calc(var(--size) / 3 * -1);
  display          : flex;
  justify-content  : center;
  align-items      : center;
  @include size(var(--size));
  border-radius    : 50%;
  color            : var(--white-color);
  background-color : var(--red-accent);
  cursor           : pointer;

  &:hover {
    background-color : var(--red-alt-accent);
  }
}
