*,
*::before,
*::after {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;
}

:root {
  --vertical-block-space  : 1rem;
  --vertical-content-space: 1rem;

  @include from(lg) {
    --vertical-block-space: 2rem;
  }
}

body {
  margin        : 0;
  display       : flex;
  flex-direction: column;
  min-height    : calc(100vh - var(--header-height));
  overflow-x    : hidden;
}

#modal-root {
  position: relative;
  z-index : var(--z-modal);
}

.hidden.hidden {
  display: none;
}

ul.list-container {
  list-style   : none;
  padding-left : 0;
  margin-bottom: 0;
}

.form-grid {
  display: grid;
  gap    : 1rem 1.5rem;

  @include from(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.buttons-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  gap            : 1.5rem;
}

.l-block,
.cancel-l-block,
.l-section,
.cancel-l-section,
.l-big-section,
.cancel-l-big-section {
  margin-top: 0;
}

  // Bloque
.l-block {
  margin-bottom: var(--vertical-block-space);
}

.cancel-l-block {
  margin-top: calc(var(--vertical-block-space) * -1);
}

  // Sección
.l-section {
  margin-bottom: calc(var(--vertical-block-space) * 2);
}

.cancel-l-section {
  margin-top: calc(var(--vertical-block-space) * -2);
}

  // Big section
.l-big-section {
  margin-bottom: calc(var(--vertical-block-space) * 4);
}

.cancel-l-big-section {
  margin-top: calc(var(--vertical-block-space) * -4);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

p,
video,
blockquote,
article,
section,
form,
figure,
iframe,
ul,
ol,
pre,
hr,
dl,
address,
table {
  margin-top: 0;
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: 0;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.center-middle {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.gap-4 {
  gap: 4rem;
}
