.publication-files {
  display     : grid;
  gap         : 1rem;
  padding-top : 1rem;

  &:has(.pdf-file:nth-child(2)) {
    @include from(md) {
      grid-template-columns : 1fr 1fr;
    }
  }
}

.pdf-file {
  position : relative;

  .pdf-header {
    display          : flex;
    gap              : 0.5rem;
    justify-content  : space-between;
    align-items      : center;
    padding          : 0.5rem 1rem;
    background-color : var(--grey-bg);
    border-radius    : var(--normal-radius);
    font-family      : var(--button-font);

    .button,
    .action-button {
      flex : none;
    }
  }

  &:has(.react-pdf__Document) .pdf-header {
    border-radius : var(--normal-radius) var(--normal-radius) 0 0;
  }

  .title {
    display     : flex;
    align-items : center;
    gap         : 0.5rem;

    .text {
      display            : -webkit-box;
      -webkit-box-orient : vertical;
      -webkit-line-clamp : 1;
      height             : var(--body-line-height);
      overflow-y         : hidden;
    }
  }

  .react-pdf__Document {
    border        : 1px solid var(--border-color);
    border-top    : none;
    border-radius : 0 0 var(--normal-radius) var(--normal-radius);
  }
}

.react-pdf__Document {
  canvas {
    max-width : 100%;
  }
}

.react-pdf__Page__textContent {
  display : none;
}
