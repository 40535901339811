.publication-button {
  all             : unset;
  display         : flex;
  justify-content : center;
  align-items     : center;
  gap             : 0.25rem;
  flex-wrap       : wrap;
  padding         : 0.25rem 0.5rem;
  border-radius   : var(--normal-radius);
  font     : {
    family : var(--button-font);
    size   : var(--text-small-size);
  }
  color           : var(--text-light-color);
  letter-spacing  : 0.3px;
  cursor          : pointer;
  white-space     : nowrap;

  &:hover {
    background : var(--grey-alt-bg);
  }

  .icon {
    flex : none;
  }

  .brand {
    @include size(0.875rem);
    object-fit : contain;
  }

  &.tiny {
    padding       : 0 0.25rem;
    font-size     : var(--text-smallest-size);
    border-radius : var(--small-radius);
  }
}
