.action-button {
  all              : unset;
  display          : flex;
  justify-content  : center;
  align-items      : center;
  @include size(2rem);
  background-color : var(--grey-alt-bg);
  cursor           : pointer;
  outline          : none;
  border-radius    : var(--normal-radius);
  transition       : var(--transition-time);

  &:hover {
    background-color : var(--grey-bg);
  }

  .icon {
    color : var(--text-color);
  }

  &.blue {
    background-color : var(--blue-100);

    .icon {
      fill : var(--blue-600);
    }

    &:hover {
      background-color : var(--blue-200);
    }
  }

  input {
    display : none;
  }
}
