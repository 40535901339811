*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color     : var(--text-color);
  background: var(--grey-bg);
}

a {
  color: var(--blue-400);
}

::selection {
  background: var(--blue-accent);
  color     : #FFF;
}

button {
  font-family   : var(--button-font);
  letter-spacing: 0.3px;
}

hr {
  border       : none;
  border-bottom: 1px solid var(--border-color);
  margin-left  : initial;
  margin-right : initial;
}

:root {
  --header-height: 3rem;
  --top-content  : calc(var(--header-height) + 2rem);

  @include from(lg) {
    --header-height: 4rem;
  }

      /* ----- Shadows ----- */
  --modal-shadow   : 0 1rem 40px #{rgba(#7070B0, 0.2)};
  --card-shadow    : 0 2px 3px #{rgba(#495459, 0.1)};
  --tooltip-shadow : 0 2px 8px #{rgba(#787D93, 0.15)};
  --document-shadow: 0 2px 5px #{rgba(#495459, 0.5)};

      /* ----- Padding ----- */
  --container-padding: 1.5rem 2rem;
}
