/* ----- Reaction panel ----- */
.fade-container {
  position : absolute;
  top      : -2.75rem;
}

.reaction-panel {
  padding          : 0.25rem;
  display          : flex;
  gap              : 0.35rem;
  background-color : var(--white-color);
  border-radius    : 2.25rem;
  box-shadow       : var(--tooltip-shadow);
}

/* ----- Reaction button ----- */
@mixin reaction-color($class, $foreground, $background) {
  &.#{$class} {
    --foreground-color : #{$foreground};
    --background-color : #{$background};
  }

  @at-root .publication-button.comment.#{$class} {
    color : $foreground;
  }
}

@mixin reaction-size($class, $reaction-size, $icon-size) {
  &.#{$class} {
    .reaction {
      @include size($reaction-size);
      border-width : 1px;

      svg,
      .isotype {
        @include size($icon-size);
      }
    }
  }
}

.reaction-item {
  position : relative;

  &:hover:not(.micro, .tiny) {
    .reaction {
      transform : scale(1.1);
    }

    .tooltip {
      opacity   : 1;
      transform : translate(-50%, 0);
    }
  }

  .reaction {
    display          : flex;
    justify-content  : center;
    align-items      : center;
    @include size(2.25rem);
    border           : 2px solid var(--foreground-color);
    border-radius    : 50%;
    color            : var(--foreground-color);
    background-color : var(--background-color);
    cursor           : pointer;
    transition       : transform var(--min-transition-time);

    svg,
    .isotype {
      @include size(1.25rem);
    }
  }

  .tooltip {
    content          : attr(data-tooltip);
    position         : absolute;
    left             : 50%;
    top              : -1.5rem;
    transform        : translate(-50%, 0.5rem);
    padding-inline   : 0.375rem;
    border-radius    : 1rem;
    background-color : var(--title-color);
    opacity          : 0;
    color            : var(--white-color);
    font-family      : var(--button-font);
    font-size        : var(--text-smaller-size);
    white-space      : nowrap;
    transition       : opacity var(--transition-time), transform var(--transition-time);
  }

  @include reaction-color(like, #0052FD, #EEF2FF);
  @include reaction-color(love, #F46853, #FDE9E0);
  @include reaction-color(excellent, #13AC86, #E5F5DF);
  @include reaction-color(interesting, #FFB404, #FFF7DE);
  @include reaction-color(proud, #596973, #FAFAFA);

  @include reaction-size(small, 1.25rem, 0.75rem);
  @include reaction-size(tiny, 1rem, 0.6rem);
  @include reaction-size(micro, 0.75rem, 0.5rem);
}

/* ----- Reaction button ----- */
.reaction-button {
  position : relative;
}


/* ----- Reaction counter ----- */
.reaction-counter {
  display   : flex;
  gap       : 0.25rem;
  font-size : var(--text-smaller-size);
  cursor    : pointer;

  .reactions {
    display        : flex;
    gap            : 2px;
    pointer-events : none;
  }
}
