.welcome-container {
  text-align : center;
  font-size  : var(--text-small-size);

  a {
    text-decoration : none;
  }

  .title,
  .subtitle,
  .story-graphic {
    margin-bottom : 1.5rem;

    &:has(+ .subtitle) {
      margin-bottom : 0.5rem;
    }
  }

  .gap-bottom {
    margin-bottom : 0.5rem;
  }

  .n-container {
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    padding         : 2rem;
    box-shadow      : var(--modal-shadow);

    @include from(sm) {
      max-width : 450px;
      padding   : 4rem;
    }
  }

  .company-logo {
    margin-bottom : 2rem;
  }

  .form {
    display : grid;
    gap     : 1.5rem;
    width   : 100%;
  }

  .input-password .form-item {
    margin-bottom : 0.75rem;
  }

  .story-graphic {
    display       : block;
    margin-inline : auto;
  }
}
