// Grid
.grid {
  display: grid;
  gap: 2rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}

.cols-2 {
  grid-column-end: span 2;
}

.cols-3 {
  grid-column-end: span 3;
}

.cols-4 {
  grid-column-end: span 4;
}
