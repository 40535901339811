@mixin create-variables($map, $prefix: '') {
  :root {
    @for $index from 1 through length($map) {
      // Nombre del valor
      $key           : nth(nth($map, $index), 1);
      // Valor
      $value         : nth(nth($map, $index), 2);
      // Selector construído usando el $key y el $value
      // Se le puede pasar un prefijo
      $selector      : if($prefix == '', $key, $prefix + '-' + $key);
      // Variable CSS
      --#{$selector} : #{$value};
    }
  }
}
