.select-template {
  display         : flex;
  align-items     : center;
  justify-content : center;
  gap             : 0.25rem;
  padding-inline  : 0.5rem;
  font-size       : var(--text-small-size);
  transition      : background-color var(--transition-time);
  border-radius   : var(--small-radius);
  cursor          : pointer;
  user-select     : none;

  &:hover {
    background-color : var(--grey-alt-bg);
  }
}

.dropdown-select {
  position : relative;
  z-index  : var(--z-fixed);

  .caret {
    transition : transform var(--min-transition-time);
  }

  // Active status
  &:has(.float-container) .caret {
    transform : rotate(-180deg);
  }
}

.dropdown-item {
  all           : unset;
  display       : flex;
  padding-block : 0.15rem;
  color         : var(--text-light-color);
  font-size     : var(--text-small-size);
  white-space   : nowrap;
  cursor        : pointer;

  &:last-child {
    padding-bottom : 0;
  }

  &:first-child {
    padding-top : 0;
  }

  @include active-status {
    color : var(--blue-accent);
  }
}
