.user-reaction {
  display     : flex;
  align-items : center;
  gap         : 0.5rem;

  .avatar-container {
    position : relative;
  }

  .reaction-item {
    position : absolute;
    bottom   : 0;
    right    : 0;
  }
}
