.network-input {
  display     : flex;
  gap         : 0.35rem;
  align-items : center;
  font-size   : var(--text-small-size);

  .form-item {
    width : 100%;
  }

  .icon {
    flex : none;
  }

  &.linkedin .icon {
    margin-top : -0.25rem;
  }
}
