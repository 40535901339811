.reactions-modal {
  .title {
    text-align    : center;
    margin-bottom : 1rem;
  }

  .reactions-header {
    display       : flex;
    border-bottom : 1px solid var(--border-color);
    margin-bottom : 1rem;

    &:has(.tab-template:hover) .tab-template:not(:hover) {
      opacity : 0.5;
    }
  }

  .tab-template {
    padding : 0 0.5rem 0.25rem;
    cursor  : pointer;

    &.active {
      color      : var(--blue-accent);
      box-shadow : 0 2px;
    }
  }

  .panel-template {
    display : grid;
    gap     : 0.5rem;
  }
}
