.respond-form {
  display        : flex;
  flex-direction : column;
  gap            : 0.5rem;

  .input-container {
    display : flex;
    gap     : var(--photo-gap);

    &:not(:has(.input-field:not(:empty))) ~ .button {
      display : none;
    }
  }

  .button {
    margin-left : auto;
  }

  &.answer {
    padding-left : var(--answer-padding);
  }
}
