.comment {
  display : inline-flex;
  gap     : var(--photo-gap);

  .globe {
    padding          : 0.375rem 0.75rem;
    margin-bottom    : 0.25rem;
    background-color : var(--grey-bg);
    border-radius    : var(--normal-radius);
  }

  .header-comment {
    display     : flex;
    align-items : center;
    gap         : 0.5rem;
  }

  .name {
    margin    : 0;
    font-size : var(--text-small-size);
  }

  .time {
    font-size : var(--text-smaller-size);
    color     : var(--text-light-color);
  }

  .content-comment {
    font-size : var(--text-smaller-size);
    margin    : 0;
  }

  .footer-comment {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    gap             : 1rem;
  }

  .mention {
    color : var(--blue-accent);
  }

  .counter-text {
    display : none;
  }

  &.answer {
    padding-left : var(--answer-padding);
  }
}
