.main-header {
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  position        : fixed;
  top             : 0;
  left            : 0;
  padding-inline  : 1.5rem;
  height          : var(--header-height);
  width           : 100%;
  background      : var(--container-bg);
  border-bottom   : 1px solid var(--border-color);
  z-index         : var(--z-fixed);

  .company-logo,
  .logo {
    height : 1.5rem;
  }

  .right {
    display     : flex;
    align-items : center;
    gap         : 1rem;
  }
}
