@keyframes fade-in {
  from {
    opacity : 0;
  }

  to {
    opacity : 1;
  }
}

@keyframes fade-out {
  from {
    opacity : 1;
  }

  to {
    opacity : 0;
  }
}

@keyframes fade-pop-up-in {
  from {
    opacity   : 0;
    transform : scale(0.8);
  }

  to {
    opacity   : 1;
    transform : scale(1);
  }
}

@keyframes fade-pop-up-out {
  to {
    opacity   : 0;
    transform : scale(0.8);
  }
}

@keyframes fade-pop-up-in-top {
  from {
    opacity          : 0;
    transform        : scale(0.8);
    transform-origin : bottom;
  }

  to {
    opacity          : 1;
    transform        : scale(1);
    transform-origin : bottom;
  }
}

@keyframes fade-pop-up-out-top {
  from {
    opacity          : 1;
    transform        : scale(1);
    transform-origin : bottom;
  }

  to {
    opacity          : 0;
    transform        : scale(0.8);
    transform-origin : bottom;
  }
}
