.feed-empty-state {
  text-align : center;

  .title {
    margin-bottom : 0.5rem;
  }

  .text {
    margin-bottom : 2rem;
  }
}
