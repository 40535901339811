.calendar-item {
  all              : unset;
  display          : grid;
  place-items      : center;
  font-size        : var(--text-small-size);
  padding          : 0.25rem 0.5rem;
  background-color : var(--blue-50);
  border-radius    : var(--normal-radius);
  cursor           : pointer;

  @include active-status {
    color            : var(--white-color);
    background-color : var(--blue-400);
  }

  &:disabled,
  &:disabled:hover {
    color            : var(--text-lighter-color);
    cursor           : not-allowed;
    background-color : var(--grey-bg);
  }
}

.calendar-item-container {
  --columns             : 2;
  display               : grid;
  grid-template-columns : repeat(var(--columns), 1fr);
  gap                   : 0.5rem;
  min-width             : rem(200px);

  &.three-columns {
    --columns : 3;
    min-width : rem(324px);
  }
}
