::-webkit-scrollbar {
  @include size(0.6rem);
}

::-webkit-scrollbar-thumb {
  background    : #D3D1CB;
  border-radius : var(--small-radius);
}

::-webkit-scrollbar-thumb {
  &:hover,
  &:active {
    background : #b3b3b3;
  }
}

::-webkit-scrollbar-track {
  background    : #EDECE9;
  border-radius : var(--small-radius);
}
