.full-page-loader {
  position        : fixed;
  left            : 0;
  top             : 0;
  width           : 100%;
  height          : 100%;
  z-index         : var(--z-modal);
  background-color: rgba(255, 255, 255, 0.75);
  display         : flex;
  justify-content : center;
  align-items     : center;

  &.absolute {
    position: absolute;
  }

  &.solid-overlay {
    background-color: var(--white-color);
  }

  .loader {
    width : 80px;
    height: 60px;
  }
}
