.icon-text {
  display         : flex;
  justify-content : center;
  align-items     : center;
  gap             : 0.25rem;

  .icon {
    font-size : 1rem;
  }

  .text {
    margin-bottom : 0;
  }

  &.small {
    gap : 0.125rem;

    .icon {
      font-size : 0.85rem;
    }

    .text {
      font-size : var(--text-small-size);
    }
  }
}
