:where(.employer-avatar) {
  --size: 1.5rem;
  @include size(var(--size));
  border-radius: 10%;
  overflow     : hidden;
  flex         : none;

  @include from(md) {
    --size: 2rem;
  }

  img {
    width     : 100%;
    height    : 100%;
    object-fit: cover;
  }

  &.small {
    --size: 1.25rem;

    @include from(md) {
      --size: 1.5rem;
    }
  }

  &.big {
    --size: 2rem;

    @include from(md) {
      --size: 2.5rem;
    }
  }

  &.bigger {
    --size: 3rem;

    @include from(md) {
      --size: 4rem
    }
  }

  .empty {
    width           : 100%;
    height          : 100%;
    background-color: var(--blue-100);
    font-size       : 3rem;
    color           : var(--white);
    display         : flex;
    justify-content : center;
    align-items     : center;
  }
}
