.country-select {
  .select-template {
    background-color: var(--blue-light-accent);
  }

  &.has-caption {
    display        : flex;
    justify-content: center;
    align-items    : center;
  }
  
  .content {
    display        : flex;
    justify-content: center;
    align-items    : center;
  }
  
  .caption {
    margin-right: .5rem;
  }

  .dropdown-select {
    // max-width: 60px;
  }
}
